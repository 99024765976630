import React, { useEffect, useState } from "react";
import SidebarTile from "../atoms/sidebarTile";
import PrimaryButton from "../atoms/primaryButton";
import { useGoogleLogin } from '@react-oauth/google'; // LOGIN TO GET ACCESS TOKEN
import Button from "../atoms/Button";


export default function Sidebar() {
  const [sidebarTiles, setSidebarTiles] = useState([
    { id: 1, title: "<a href='google.com' target='_blank'>Jelaskan proses Validate Demand Plan List</a>" },
    { id: 2, title: "Berapa Plafon rawat gigi?" },
    { id: 3, title: "Apa yang meliputi rawat inap?" },
    { id: 4, title: "Bagaimana cara visit ke Telkomsel?" },
    { id: 5, title: "Temukan penginapan!" },
  ]);

  const [token, setToken] = useState()

  // const id = <GenerateUniqueId/>
  // console.log(id);

  const handleButtonClick = () => {
    // Action 1
    window.location.reload();

    // Action 2
    const newTile = {
      id: sidebarTiles.length + 1,
      title: `New Tile ${sidebarTiles.length + 1}`,
    };
    setSidebarTiles([...sidebarTiles, newTile]);
  };
  const refreshPage = () => {
    window.location.reload();
  };
  const addSidebarTile = () => {
    const newTile = {
      id: sidebarTiles.length + 1,
      title: `New Tile ${sidebarTiles.length + 1}`,
    };
    setSidebarTiles([...sidebarTiles, newTile]);
  };

  // const login = useGoogleLogin({ // LOGIN TO GET ACCESS TOKEN
    // onSuccess: tokenResponse => setToken(tokenResponse),
  // },
    // window.localStorage.setItem("tokenResponse", token?.access_token),
    // console.log(token)
  // );

  return (
    // <aside className="min-h-screen w-full h-full bg-white text-black flex flex-col justify-between " style={{ backgroundColor:"#A9CEE8"}}>
    // <aside className="min-h-screen w-full h-full bg-white text-black flex flex-col justify-between " style={{ backgroundColor:"#89CFF0"}}>
    <aside className="min-h-screen w-full h-full bg-white text-black flex flex-col justify-between " style={{ backgroundColor:"#CBD5C0"}}>
      <div>
        <div className="flex flex-row justify-center items-center mt-8">
          <img
            // src="/icons/genai-icon.png"
            src="/icons/chatbot.png"
            alt="genai Icon"
            className="w-24"
          />
        </div>
        
        <div className="my-16 mx-5">
        {/* <h1 style={{color:"#FFFFFF"}}>-Dev Env-</h1> */}
          <h1 className="font-semibold font-Arial text-2xl" style={{color:"#000080"}}>Hi There, welcome! this is our Assistent from KUP Metrodata!</h1>
          {/* <ol  className="font-Arial" class="list-disc">
            <li className="mx-5">Focus on knowledge from <a href="https://365tsel.sharepoint.com/sites/AutomationGovernance/Shared%20Documents/Forms/AllItems.aspx?fromShare=true&ga=1&id=%2Fsites%2FAutomationGovernance%2FShared%20Documents%2F04%2E%20Technology%20Roadmap%2FGCP%20Generative%20AI%20POC&viewid=a3516bd9%2D931a%2D4230%2D9303%2Dc11fb3fb6790" target="_blank" style={{color:"#FFFFFF"}}>PoC Documents</a>.</li>
            <li className="mx-5">Model tuning is ongoing process. Please let us know if you encounter any issues when testing.</li>
            <li className="mx-5"><span className="font-bold">Use desktop computer/notebook</span> for best experience. This UI is only for testing and may not be adapted for all types of screens such as mobile, tablets etc. Final product to be integrated with Telkomsel Moana App.</li>
          </ol> */}
        </div>
        {/* <div className="my-16 mx-5">
          <h1 className="font-semibold font-Arial text-2xl">Ongoing tuning:</h1>
          <ol  className="font-Arial" class="list-disc">
            <li className="mx-5">Flowcharts/diagrams - questions on describing the process with flowchart/diagrams may not return correct results.</li>
            <li className="mx-5">chat may answers questions that are not related to Telkomsel policy documents.</li>
            <li className="mx-5">Multi-turn conversations - ongoing implementations.</li>
          </ol>
        </div> */}
      </div>
      <div className="mb-14 mx-2 flex flex-col">
        <hr className="border border-white mb-14"></hr>
        <div className="flex ">
          <div className="flex justify-center ml-10">
          
          {/* <button className="border" onClick={() => login()}> 
            Sign in with Google 🚀{' '}
          </button> */}
          <img
            src="/icons/metrodata-icon.png"
            alt="Metrodata Icon"
            className="w-16 h-16"
          />
          <div className="flex ml-5 mt-4">
            <h1 className="text-xl font-Arial"  style={{color:"#000000"}}>Proprietary by Metrodata</h1></div>
            {/* <h1 className="text-xl font-Arial font-semibold text-2xl font-underline" style={{color:"#A2F111"}}>Just Playground for Us!!</h1></div> */}
          </div >
          
        </div>
      </div>
    </aside>
  );
}