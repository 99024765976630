import ReactDOM from 'react-dom/client';

// String.prototype.replaceJSX = function (find, replace) {
//   return this.split(find).flatMap((item) => [item, replace]).slice(0, -1);
// }

const textlinks = ({links}) =>{

  return (
    <>
    {links.map((link, index) => (
      link ? <span className="flex flex-col"><a href={'https://storage.cloud.google.com/'+link} target="_blank" className="italic" style={{color: "orange"}}>[{index+1}] {link}</a></span> : null
      ))}
    </>
  );

}

// const ConvertToHTML = ({text}) => {
//   let text1 = text.replaceJSX(/\r|\n/, '<br/>');
//   console.log(text1);
//   const container = document.getElementById('respond');
//   const respond = ReactDOM.createRoot(container);
  // respond.render(text1);
  // ReactDOM.render({text1}, document.getElementById('respond'));
  // return
// }

const MessageContent = ({ text, links }) => {
  // let link = <textlinks links={links} />
  // let link01 = link.props.links;
  let ref = "";
  let add = "";
  if (text.includes("maaf") || text.includes("Maaf") || text.includes("does not mention") || text.includes("Jawaban diluar konteks") || text.includes("Pertanyaan Anda tidak terkait dengan data internal Telkomsel")){
    ref = "";
    // links = "";
    add = "";
    let index = links.indexOf(links[0]);
    links.splice(index, 1);
  } else {
    ref = "Reference:";
  }

  return (
    <div className="flex flex-col" id="respond">
        {text}{add}
        <i className="italic">
          {ref}
            {links.map((link, index) => (
              // link ? <span className="flex flex-col"><a href={'https://storage.cloud.google.com/'+link} target="_blank" className="italic" style={{color: "orange"}}>[{index+1}] {link}</a></span> : null
              link ? <span className="flex flex-col"><a href={'https://storage.cloud.google.com/'+link} target="_blank" className="italic" style={{color: "orange"}}> {link}</a></span> : null
              ))}
        </i>
      </div>
      // <TypewriterComponent text={text} links={link01}/>
    );
  }

export default MessageContent;