import HeaderHome from "../components/molecules/headerHome";
import TextInput from "../components/atoms/textInput";
import HomeSuggestions from "../components/molecules/homeSuggestions";
import ChatTile from "../components/molecules/chatTile";
import React, { useState } from "react";
import axios from "axios";
import Button from "../components/atoms/Button";
import Loading from "../components/atoms/Loading";
import MessageContent from "../components/atoms/MessageContent";

export default function Home() {
  // Get the session value from sessionStorage
  const sessionId = sessionStorage.getItem("mySessionKey");
  console.log("sessionId : "+sessionId);

  const [messages, setMessages] = useState([
    { status: "receiver", content: "Gen-AI chatbot ini menggunakan data internal PT Metrodata Electronics di Data Store." }
  ]);  
  
  const handleSuggestedQuestion = async (question) => {
    setMessages([
      { status: "sender", content: question },
      ...messages
    ]);
  
    setMessages(prevMessages => [
      { status: "receiver", content: <Loading/> },
      ...prevMessages
    ]);

    // console.log(messages);

    let payload = {
      prompt: question
    };

    const path_gs = ['gs://'];

    let endpoint = '';
    endpoint = 'https://us-central1-trial-genai.cloudfunctions.net/metrodata-kup'
    try {
      const res = await axios.post(endpoint, payload);
      // console.log(res);
      const responseMessage = res.data.result
      const link1 = res.data.source_document
      let newlink1 = '';
      newlink1 = link1.replaceJSX(path_gs[0], '')[2];
      console.log(newlink1);
      if (responseMessage) {
        
        setMessages(prevMessages => [
          { status: "receiver", content: <MessageContent text={responseMessage} links={[newlink1]} />,},
          ...prevMessages.slice(1)
          
        ]);
      console.log(messages);

      } else {
        console.error("No Answer received from the backend");
      }
  
    } catch (error) {
      // console.error(error);
      setMessages(prevMessages => prevMessages.slice(1));
    }
  };
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <div className="flex flex-col py-14 px-11 justify-between min-h-screen font-Arial">
      <HeaderHome />
      <div className=" text-center text-2xl font-semibold mb-4 font-Inter">
      </div>
      <div className="flex flex-col">     
        <div className="flex flex-col-reverse overflow-y-auto max-h-[530px] pb-5 ">
          {messages.map((message, index) => (
            <div key={index}>
              <ChatTile 
                status={message.status} 
                message={message.content}  
              />
            </div>
          ))}
        </div>
        <div className="mb-4 mt-4 pl-6">
          {/* <HomeSuggestions /> */}
          <HomeSuggestions onSuggestedQuestionClick={handleSuggestedQuestion} />
        </div>
        <div className="w-full flex flex-row justify-end font-Arial">
          <div className=" mr-5 w-[30vh] ">
            <Button
              title={"New Chat"}
              fontSize={"text-xl"}
              shadow={"shadow-black"}
              onClick={refreshPage}
            />
          </div>
          <TextInput setMessages={setMessages} messages={messages}/>
        </div>  
      </div>
    </div>
  );
}
