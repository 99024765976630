import React, { useEffect, useState } from "react";

const UniqueSessionStringGenerator = () => {
  const [uniqueString, setUniqueString] = useState("");

  useEffect(() => {


    // Generate a unique string using Date.now() and Math.random()
    const newUniqueString = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    // Set the unique string in state
    setUniqueString(newUniqueString);
    sessionStorage.setItem("mySessionKey", newUniqueString);

    // Get the session value from sessionStorage
    const sessionValueFromStorage = sessionStorage.getItem("mySessionKey");
    // console.log(sessionValueFromStorage);
  }, []);

  return (
    <div>
      {/* <p>{uniqueString}</p> */}
    </div>
  );
};

export default UniqueSessionStringGenerator;