import { Icon } from "@iconify/react";
import sendIcon from "@iconify/icons-iconamoon/send";
import { useState } from "react";
import axios from "axios";
import reactStringReplace from 'react-string-replace';
import Loading from "./Loading";
import MessageContent from "./MessageContent";

export default function TextInput({ setMessages, messages }) {

  // Get the session value from sessionStorage
  const sessionId = sessionStorage.getItem("mySessionKey");
  console.log("sessionId : "+sessionId);

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

const handleSubmit = async (e) => {
  e.preventDefault();
  submitData();
};

String.prototype.replaceJSX = function (find, replace) {
  return this.split(find).flatMap((item) => [item, replace]).slice(0, -1);
}

const submitData = async () => {
  let payload = {
    prompt: value
  };
  setValue("");

  setIsLoading(true);
  setMessages([{ status: "sender", content: value }, ...messages]);
  setMessages(prevMessages => [{ status: "receiver", content: <Loading /> }, ...prevMessages]);


  let endpoint = '';
  endpoint = 'https://us-central1-trial-genai.cloudfunctions.net/metrodata-kup'
  const path_gs = 'gs://';

  try {
    // const res = await axios.post(endpoint, payload, config);
    const res = await axios.post(endpoint, payload);
    // console.log(res);

    const responseMessage = res.data.result
    const link1 = res.data.source_document
    let newlink1 = '';
    newlink1 = link1.replaceJSX(path_gs, '')[2];

    // newlink = newlink1.replaceJSX(path_gs[1], '')
    // newlink1 = link1.replaceJSX(path_gs, '');

      if (responseMessage){
        setMessages(prevMessages => [
          {
            status: "receiver",
            // content: <MessageContent text={responseMessage} links={[link1, link2, link3]} />,
            content: <MessageContent text={responseMessage} links={[newlink1]} />,
            // content: <MessageContent text={responseMessage} />,
          },
          ...prevMessages.slice(1),
        ]);
      } else{
        console.error ("Tidak ada data untuk menjawab ini.");
        setMessages(prevMessages => prevMessages.slice(1)); 
      }
    
  } catch (error) {
    console.error(error);
    setMessages(prevMessages => prevMessages.slice(1));
  }
  setIsLoading(false)
}

  return (
    <form
      name= "displayanswers" onSubmit={handleSubmit}
      className="font-Arial flex flex-row rounded-3xl border border-black shadow-grey shadow-md w-full bg-secondary-light h-16 pr-8 items-center space-x-2 dark:bg-secondary-dark dark:shadow-none"
    >
      <input
        type="text"
        name="input"
        value= {value}
        onChange={(e) => { setValue(e.target.value) }}
        className="font-Arial bg-secondary-light w-full rounded-3xl focus:ring-0 focus:border-none focus:outline-none h-full p-2 dark:bg-secondary-dark"
        autoComplete="off"
        placeholder="Type your question here...."
      />
      
    </form>
  );
  
}
