import Sidebar from "./components/molecules/sidebar";
import Home from "./pages/home";
import { GoogleOAuthProvider } from '@react-oauth/google';
import UniqueSessionStringGenerator from "./components/atoms/UniqueSessionStringGenerator";


function App() {
  return (
    // <GoogleOAuthProvider clientId="299204517154-32501orf5nc0hl0jkeui2icjpngdqqjn.apps.googleusercontent.com">
    <div className="font-inter grid grid-cols-12">
        <div className="col-span-3">
          <UniqueSessionStringGenerator />
          <Sidebar />
        </div>
        <main className="col-span-9 dark:bg-primary-light dark:text-white">
          <Home />
        </main>
      </div>
    // </GoogleOAuthProvider>
  );
}

export default App;
